import Vue from "vue";
import App from "./App.vue";
// eslint-disable-next-line
import "./style/style.scss";
import vuetify from "./plugins/vuetify";
import router from "./router";
import Vuetify from "vuetify/lib";
import { setupAndGetI18n } from "./config/setup-i18n";
import { setupComponents } from "./config/setup-components";
import VueRecaptcha from "vue-recaptcha";
import VueAnime from "vue-animejs";
import Chat from "vue-beautiful-chat";
import VueMeta from 'vue-meta';
import AOS from 'aos';
import 'aos/dist/aos.css';

Vue.use(Chat);

Vue.use(VueAnime);
Vue.use(VueMeta);
Vue.config.productionTip = false;
Vue.use(vuetify);
Vue.use(VueRecaptcha);
export default new Vuetify({
  icons: {
    iconfont: "fa",
    values: {
      cancel: "fas fa-ban",
      menu: "fas fa-ellipsis-v"
    }
  }
});
setupComponents(Vue);

const i18n = setupAndGetI18n(Vue);
new Vue({
  vuetify,
  render: h => h(App),
  router,
  i18n,
  data: {},

  methods: {
    setLanguage(language) {
      const vm = this;
      localStorage.setItem("language", language);

      document.documentElement.lang = language;

      vm.$i18n.locale = language;

      // vm.$vuetify.lang.current = language;
    }
  },
  metaInfo: () => ({
    title: 'Kien ',
    titleTemplate: '%s | Senior Software Engineer',
    htmlAttrs: {
      lang: 'en',
      amp: undefined
    },
    headAttrs: {
      test: true
    },
    bodyAttrs: {
      tabIndex: 0
    },
    meta: [
      { name: 'description', content: 'Hello, I am Kien, a Senior Software Engineer with more than 5 years experienced. Thank you so much for visit my site', vmid: 'test' }
    ],
    script: [
      { innerHTML: '{ "@context": "http://www.schema.org", "@type": "Organization" }', type: 'application/ld+json' },
      { innerHTML: '{ "body": "yes" }', body: true, type: 'application/ld+json' }
    ],
    __dangerouslyDisableSanitizers: ['script']
  }),
  created() {
    const vm = this;
    vm.setLanguage("en");
    AOS.init()
  }
}).$mount("#app");
