import Vue from "vue";
import Router from "vue-router";

import KienPortFolio from "../components/KienPortFolio.vue";

Vue.use(Router);

export default new Router({
  routes: [
    {
      path: "/",
      name: "HelloWorld",
      component: KienPortFolio,
      meta: {
        breadcrumb: [{ name: "KienPortFolio" }]
      }
    }
  ]
});
