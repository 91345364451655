var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('img',{directives:[{name:"anime",rawName:"v-anime",value:({
        targets: '.specific-prop-params-demo .el',
        translateX: {
          value: 80,
          duration: 800
        },
        rotate: {
          value: 360,
          duration: 1800,
          easing: 'easeInOutSine'
        },
        scale: {
          value: 1.2,
          duration: 1600,
          delay: 800,
          easing: 'easeInOutQuart'
        },
        loop: true,
        delay: 250
      }),expression:"{\r\n        targets: '.specific-prop-params-demo .el',\r\n        translateX: {\r\n          value: 80,\r\n          duration: 800\r\n        },\r\n        rotate: {\r\n          value: 360,\r\n          duration: 1800,\r\n          easing: 'easeInOutSine'\r\n        },\r\n        scale: {\r\n          value: 1.2,\r\n          duration: 1600,\r\n          delay: 800,\r\n          easing: 'easeInOutQuart'\r\n        },\r\n        loop: true,\r\n        delay: 250\r\n      }"}],attrs:{"src":require("../assets/kielogo.svg"),"alt":"Kien Portfolio Icon","width":"50","height":"50"}})])
}
var staticRenderFns = []

export { render, staticRenderFns }