import VueI18n from "vue-i18n";

export function setupAndGetI18n(Vue) {
  Vue.use(VueI18n);

  const i18n = new VueI18n({
    locale: "en",
    fallbackLocale: "en",
    fallbackRoot: false,
    silentTranslationWarn: true,

    // eslint-disable-next-line
    missing(locale, key, vm) {
      // TODO
      return key;
    }
  });

  i18n.setLocaleMessage("en", require("../../src/i18n/en.json"));
  i18n.setLocaleMessage("de", require("../../src/i18n/de.json"));
  i18n.setLocaleMessage("ja", require("../../src/i18n/ja.json"));

  return i18n;
}
