import firebase from "firebase";
import "firebase/database";
// firebase init - add your own config here
  // Your web app's Firebase configuration
var firebaseConfig = {
apiKey: "AIzaSyC4aJc5N176G-DjXmxtHnbgXG_pxTqhZ2g",
authDomain: "kienportfolio.firebaseapp.com",
databaseURL: "https://kienportfolio.firebaseio.com",
projectId: "kienportfolio",
storageBucket: "kienportfolio.appspot.com",
messagingSenderId: "640594504785",
appId: "1:640594504785:web:13a39dec91a79e556b9c70"
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);

export default firebase.database();