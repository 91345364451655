<template>
  <div>
    <img
      src="../assets/kielogo.svg"
      alt="Kien Portfolio Icon"
      width="50"
      height="50"
      v-anime="{
        targets: '.specific-prop-params-demo .el',
        translateX: {
          value: 80,
          duration: 800
        },
        rotate: {
          value: 360,
          duration: 1800,
          easing: 'easeInOutSine'
        },
        scale: {
          value: 1.2,
          duration: 1600,
          delay: 800,
          easing: 'easeInOutQuart'
        },
        loop: true,
        delay: 250
      }"
    />
  </div>
</template>
<script>
export default {
  name: "icon-animation",
  data() {
    return {};
  },
  mounted() {
  }
};
</script>
