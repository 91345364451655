<template>
  <v-app>
    <v-main>
      <KienPortFolio />
    </v-main>
    <v-container
      v-bind:style="
        !isMobile() ? 'width: 1185px !important' : 'width: 100% !important'
      "
    >
      <v-footer padless>
        <v-card
          flat
          tile
          style="padding-top: 65px !important; width: 100%; height: 100%"
          class="footer-style lighten-1 white--text text-center"
          height="300"
        >
          <v-spacer></v-spacer>
          <v-card-text>
            <v-btn
              v-for="icon in icons"
              :key="icon.index"
              class="mx-4 white--text"
              icon
            >
              <v-icon @click="goToPage(icon.url)" size="44px">
                {{ icon.icon }}
              </v-icon>
            </v-btn>
          </v-card-text>
          <v-card-text class="white--text pt-0 mt-5">
            <h2 class="mt-2 mb-3">{{$t("blogqoutes")}}</h2>  
            <h3>{{ $t("introductionFooter") }}</h3>
          </v-card-text>
          <v-row class="text-left m-0">
            <v-spacer></v-spacer>
            <v-spacer></v-spacer>
          </v-row>
          <v-row class="text-left m-0">
            <v-spacer></v-spacer>
            <v-spacer></v-spacer>
          </v-row>
          <v-card-text class="white--text"
            >© {{ new Date().getFullYear() }} —
            <strong>{{$t("siteOwner")}}</strong>
          </v-card-text>
          <v-spacer></v-spacer>
        </v-card>
      </v-footer>
    </v-container>
  </v-app>
</template>

<script>
import KienPortFolio from "./components/KienPortFolio";
export default {
  name: "App",
  components: {
    KienPortFolio
  },
  data: () => ({
    icons: [
      {
        index: 1,
        icon: "mdi-facebook",
        url: "https://www.facebook.com/kiennguyen1994"
      },
      {
        index: 2,
        icon: "mdi-twitter",
        url: "https://twitter.com/Trung_Kien_94"
      },
      {
        index: 3,
        icon: "mdi-linkedin",
        url: "https://www.linkedin.com/in/kien-nguyen-081206163/"
      },
      {
        index: 4,
        icon: "mdi-instagram",
        url: "mdi-facebook"
      }
    ]
  }),
  methods: {
    goToPage(url) {
      window.open(url);
    },
    isMobile() {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        return true;
      }
      return false;
    }
  }
};
</script>

<style scoped lang="scss">
.footer-style {
  background-color: #751fed !important;
  width: 100% !important;
}
</style>
