<template>
<v-container v-bind:style="!isMobile() ? 'width: 1185px !important' : ''">
    <v-dialog v-model="dialog" max-width="590">
        <v-card>
            <h2 class="become-mentor ml-5">
                {{ $t("becomeMentor1")}}
            </h2>
            <h3 class="mt-5 ml-5">
                {{ $t("becomeMentorContent") }}
            </h3>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="#751fed" text @click="dialog = false">
                    {{$t("close")}}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
    <beautiful-chat style="position: relative; z-index:999;" :participants="participants" :titleImageUrl="titleImageUrl" :onMessageWasSent="onMessageWasSent" :messageList="messageList" :newMessagesCount="newMessagesCount" :isOpen="isChatOpen" :close="closeChat" :icons="icons" :open="openChat" :showEmoji="true" :showFile="true" :showEdition="true" :showDeletion="true" :showTypingIndicator="showTypingIndicator" :showLauncher="true" :showCloseButton="true" :colors="colors" :alwaysScrollToBottom="alwaysScrollToBottom" :messageStyling="messageStyling" @onType="handleOnType" @edit="editMessage" />
    <v-row>
        <v-col>
            <IconAnimation />
        </v-col>
        <v-col md="auto" align-end>
            <v-btn outlined color="indigo" class="btn-become-mentor mr-5" @click.stop="dialog = true">
                {{ $t("becomeMyMentor") }}
            </v-btn>
            <v-btn rounded class="btn-mentor-ship mr-5" depressed elevation="2" dark raised x-large @click="clickSayHello()">{{ $t("sayHello") }}</v-btn>
            <v-menu offset-y class="toolbar-menu-item" origin="center center" :nudge-bottom="10" transition="scale-transition" content-class="language-menu">
                <!-- eslint-disable-next-line -->
                <template v-slot:activator="{ on, attrs }">
                    <v-btn icon large slot="activator" :ripple="false" v-bind="attrs" v-on="on">
                        <img :src="selectedLanguageFlag" class="selected-language-flag" alt />
                    </v-btn>
                </template>
                <v-list>
                    <v-list-item v-for="(language, index) in languages" @click="selectLanguage(language.languageCode)" :key="index" class="languages-list-item">
                        <v-list-tile-action v-if="language.path">
                            <img :src="language.path" class="language-flag" alt />
                        </v-list-tile-action>
                        <v-list-tile-content class="languages-list-item-title">
                            <v-list-item-title>{{ language.name }}</v-list-item-title>
                        </v-list-tile-content>
                    </v-list-item>
                </v-list>
            </v-menu>
        </v-col>
    </v-row>
    <v-row class="text-center">
        <v-col cols="12" sm="6" md="8">
            <h1 style="text-align: left !important" class="job-title font-weight-bold mb-3">
                {{ $t("sayHi") }}
            </h1>
            <typical 
                v-if="!isMobile()" 
                style="text-align: left !important" class="welcome-content font-weight-regular"
                :steps="[this.typicalStr1, 1000, this.typicalStr2, 500, this.typicalStr3, this.loopNumber]"
                :loop=100 
                v-show="onLangChange"
                :wrapper="'h3'"
            ></typical>
            <typical 
                v-if="isMobile()" 
                style="text-align: left !important" class="welcome-content font-weight-regular"
                :steps="[this.typicalStr1, 1000, this.typicalStr2, 500, this.typicalStr3, this.loopNumber]"
                :loop=1 
                v-show="onLangChange"
                :wrapper="'h3'"
            ></typical>
            <h2 style="text-align: left !important" class="welcome-content-2 font-weight-regular">{{ $t("introduction4") }}</h2>
        </v-col>
        <v-col v-if="!isMobile()" cols="6" md="4">
            <v-img style="margin-top:6rem;" lazy-src="https://picsum.photos/id/11/10/6" max-height="850" max-width="450" src="../assets/images/avt.jpg"></v-img>  
        </v-col>
        <v-col v-if="isMobile()" cols="12">
            <v-img style="margin-top:6rem;" lazy-src="https://picsum.photos/id/11/10/6" max-height="850" max-width="450" src="../assets/images/avt.jpg"></v-img>  
        </v-col>
    </v-row>
    <v-row justify="space-around">
    </v-row>
    <v-row class="text-center">
        <v-col class="mb-4 mt-4">
            <v-row>
                <h2 class="subheading font-weight-regular">{{ $t("introductionYourSelf") }}</h2>
            </v-row>
        </v-col>
    </v-row>
    <v-row justify="center">
        <h2  v-bind:style="!isMobile() ? '' : 'text-align: center;'" class="happy-client mb-2">{{ $t("visitContent1")}} 3000 {{ $t("visitContent2")}} {{ $t("visitContent3")}} </h2>
    </v-row>
    <v-divider class="mt-5"></v-divider>
    <v-row class="experienced-row" justify="center">
        <h1 class="happy-client mb-5">{{ $t("strongSkill") }}</h1>
    </v-row>
    <v-row>
        <v-spacer></v-spacer>
        <v-card width="100%" max-width="1200px">
            <v-toolbar flat class="strongest-skill" dark>
                <v-toolbar-title>{{$t("pointAboutMe")}}</v-toolbar-title>
            </v-toolbar>
            <v-tabs color="#751fed" vertical>
                <v-tab class="mt-5">{{ $t("aboutMe1") }}</v-tab>
                <v-tab class="mt-5">{{ $t("aboutMe2") }}</v-tab>
                <v-tab class="mt-5">{{ $t("aboutMe3") }}</v-tab>
                <v-tab class="mt-5">{{ $t("aboutMe4") }}</v-tab>
                <v-tab-item>
                    <v-card flat>
                        <v-card-text>
                            <ul>
                                <li>
                                    <h2 class="mt-5">
                                        <b>{{$t("fullName")}}:</b> {{ $t("portfolioName") }}
                                    </h2>
                                </li>
                                <li>
                                    <h2 class="mt-5">
                                        <b>{{$t("gender")}}:</b> {{$t("genderValue")}}
                                    </h2>
                                </li>
                                <li>
                                    <h2 class="mt-5">   
                                        <b>{{$t("age")}}:</b> {{$t("ageValue")}}
                                    </h2>
                                </li>
                                <li>
                                    <h2 class="mt-5">
                                        <b>{{$t("university")}}:</b> {{ $t("universityDegree") }}
                                    </h2>
                                </li>
                                <li>
                                    <h2 class="mt-5">
                                        <b>{{$t("hometown")}}:</b> {{ $t("homeTown") }}
                                    </h2>
                                </li>
                            </ul>
                        </v-card-text>
                    </v-card>
                </v-tab-item>
                <v-tab-item>
                    <v-card flat>
                        <v-card-text>
                            <ul>
                                <li>
                                    <h2 class="mt-5">
                                        <b>{{$t("highSchool")}}:</b> {{ $t("highSchoolValue") }}
                                    </h2>
                                </li>
                                <li class="mt-5">
                                    <h2 class="mt-5">
                                        <b>{{$t("university")}}:</b> {{ $t("universityDegree") }}
                                    </h2>
                                </li>
                                <li class="mt-5">
                                    <h2 class="mt-5">
                                        <b>{{$t("learning")}}:</b> {{ $t("selfLearning") }}
                                    </h2>
                                </li>
                            </ul>
                        </v-card-text>
                    </v-card>
                </v-tab-item>
                <v-tab-item>
                    <v-card flat>
                        <v-card-text>
                            <ul>
                                <li>
                                    <h2 class="mt-5">
                                        <b>{{ $t("languageSkill1")}}:</b> {{ $t("languageContent1")}}
                                    </h2>
                                </li>
                                <v-spacer></v-spacer>
                                <li class="mt-5">
                                    <h2 class="mt-5">
                                        <b>{{ $t("languageSkill2")}}:</b> {{ $t("languageContent2")}}
                                    </h2>
                                </li>
                                <v-spacer></v-spacer>
                                <li class="mt-5">
                                    <h2 class="mt-5">
                                        <b>{{ $t("languageSkill3")}}:</b> {{ $t("languageContent3")}}
                                    </h2>
                                </li>
                            </ul>
                        </v-card-text>
                    </v-card>
                </v-tab-item>
                <v-tab-item>
                    <v-card flat>
                        <v-card-text>
                            <ul>
                                <li>
                                    <h2 class="mt-5">
                                        <b>{{$t("travel")}}:</b>
                                       {{ $t("travelValue") }}
                                    </h2>
                                </li>
                                <li>
                                    <h2 class="mt-5">
                                        <b>{{$t("music")}}:</b>
                                        {{ $t("musicValue") }}
                                    </h2>
                                </li>
                                <li>
                                    <h2 class="mt-5">
                                        <b>{{$t("game")}}:</b>
                                        {{ $t("gameValue") }}
                                    </h2>
                                </li>
                            </ul>
                        </v-card-text>
                    </v-card>
                </v-tab-item>
            </v-tabs>
        </v-card>
        <v-spacer></v-spacer>
    </v-row>
    <v-divider class="mt-5"></v-divider>
    <v-row class="happy-client-row" justify="center">
        <h1 class="happy-client mb-5">{{ $t("projectTitle") }}</h1>
    </v-row>
    <v-row>
        <v-spacer v-if="!isMobile()"></v-spacer> 
        <v-sheet
            class="mx-auto"
            justify="center"
            elevation="8"
            width="100%"
        >
            <v-slide-group
            v-model="model"
            class="pa-4"
            active-class="success"
            show-arrows
            >
                <v-slide-item>
                    <v-col xs="6">
                        <v-card :loading="loading" class="mx-auto my-12" max-width="374">
                            <template slot="progress">
                                <v-progress-linear color="deep-purple" height="10" indeterminate></v-progress-linear>
                            </template>
                            <v-img max-width="350" height="250" src="../assets/images/project10.jpg"></v-img>
                            <v-card-title>{{ $t("projectTitle8") }}</v-card-title>
                            <v-card-text>
                                <h3 class="project-subtitle mb-4">{{ $t("projectSubTitle8") }}</h3>
                                <div>
                                    {{ $t("projectDetail8")}}
                                </div>
                            </v-card-text>
                            <v-divider class="mx-4"></v-divider>
                            <v-card-title>{{ $t("technologyUsed") }}</v-card-title>
                            <v-card-text>
                                <v-chip-group active-class="deep-purple accent-4 white--text" column>
                                    <v-chip>Reactjs</v-chip>
                                    <v-chip>Nodejs</v-chip>
                                    <v-chip>MySQL</v-chip>
                                    <v-chip>Stripe</v-chip>
                                    <v-chip>HOC</v-chip>
                                    <v-chip>Docker</v-chip>
                                    <v-chip>Gitlab CI</v-chip>
                                    <v-chip>System Design</v-chip>
                                    <v-chip>Database Design</v-chip>
                                </v-chip-group>
                            </v-card-text>
                            <v-card-actions>
                                <v-btn color="deep-purple lighten-2" text @click="gotoPage(2)">{{$t("readMore")}}</v-btn>
                                <v-spacer></v-spacer>
                                <v-chip class="ma-2" color="orange" label outlined @click:close="chip4 = false"> {{ $t("projectStatus2") }} </v-chip>
                            </v-card-actions>
                        </v-card>
                    </v-col>
                </v-slide-item>
                <v-slide-item>
                    <v-col xs="6">
                        <v-card :loading="loading" class="mx-auto my-12" max-width="374">
                            <template slot="progress">
                                <v-progress-linear color="deep-purple" height="10" indeterminate></v-progress-linear>
                            </template>
                            <v-img max-width="350" height="250" src="../assets/images/project9.jpg"></v-img>
                            <v-card-title>{{ $t("projectTitle9") }}</v-card-title>
                            <v-card-text>
                                <h3 class="project-subtitle mb-4">{{ $t("projectSubTitle9") }}</h3>
                                <div>
                                    {{ $t("projectDetail9")}}
                                </div>
                            </v-card-text>
                            <v-divider class="mx-4"></v-divider>
                            <v-card-title>{{ $t("technologyUsed") }}</v-card-title>
                            <v-card-text>
                                <v-chip-group active-class="deep-purple accent-4 white--text" column>
                                    <v-chip>Nuxtjs</v-chip>
                                    <v-chip>PHP Laravel</v-chip>
                                    <v-chip>Postgres</v-chip>
                                    <v-chip>Momo</v-chip>
                                    <v-chip>ZaloPay</v-chip>
                                    <v-chip>Docker</v-chip>
                                    <v-chip>Forge</v-chip>
                                    <v-chip>Devops</v-chip>
                                    <v-chip>System Design</v-chip>
                                </v-chip-group>
                            </v-card-text>
                            <v-card-actions>
                                <v-btn color="deep-purple lighten-2" text @click="gotoPage(1)">{{$t("readMore")}}</v-btn>
                                <v-spacer></v-spacer>
                                <v-chip class="ma-2" color="orange" label outlined @click:close="chip4 = false"> {{ $t("projectStatus2") }} </v-chip>
                            </v-card-actions>
                        </v-card>
                    </v-col>
                </v-slide-item>
                <v-slide-item>
                    <v-col xs="6">
                        <v-card :loading="loading" class="mx-auto my-12" max-width="374">
                            <template slot="progress">
                                <v-progress-linear color="deep-purple" height="10" indeterminate></v-progress-linear>
                            </template>
                            <v-img max-width="350" height="250" src="../assets/images/project7.jpg"></v-img>
                            <v-card-title>{{ $t("projectTitle7") }}</v-card-title>
                            <v-card-text>
                                <h3 class="project-subtitle mb-4">{{ $t("projectSubTitle7") }}</h3>
                                <div>
                                    {{ $t("projectDetail7")}}
                                </div>
                            </v-card-text>
                            <v-divider class="mx-4"></v-divider>
                            <v-card-title>{{ $t("technologyUsed") }}</v-card-title>
                            <v-card-text>
                                <v-chip-group active-class="deep-purple accent-4 white--text" column>
                                    <v-chip>Vuejs</v-chip>
                                    <v-chip>Nodejs</v-chip>
                                    <v-chip>Nuxtjs</v-chip>
                                    <v-chip>Tailwind</v-chip>
                                    <v-chip>Atomic Concept</v-chip>
                                    <v-chip>.Net</v-chip>
                                    <v-chip>SQL Server</v-chip>
                                    <v-chip>System Design</v-chip>
                                    <v-chip>Database Design</v-chip>
                                </v-chip-group>
                            </v-card-text>
                            <v-card-actions>
                                <v-btn color="deep-purple lighten-2" text @click="gotoPage(3)">{{$t("readMore")}}</v-btn>
                                <v-spacer></v-spacer>
                                <v-chip class="ma-2" color="orange" label outlined @click:close="chip4 = false"> {{ $t("projectStatus2") }} </v-chip>
                            </v-card-actions>
                        </v-card>
                    </v-col>
                </v-slide-item>
                <v-slide-item>
                    <v-col xs="6">
                        <v-card :loading="loading" class="mx-auto my-12" max-width="374">
                            <template slot="progress">
                                <v-progress-linear color="deep-purple" height="10" indeterminate></v-progress-linear>
                            </template>
                            <v-img max-width="350" height="250" src="../assets/images/project1.png"></v-img>
                            <v-card-title>{{ $t("projectTitle1") }}</v-card-title>
                            <v-card-text>
                                <h3 class="project-subtitle mb-4">{{ $t("projectSubTitle1") }}</h3>
                                <div>
                                    {{ $t("projectDetail1")}}
                                </div>
                            </v-card-text>
                            <v-divider class="mx-4"></v-divider>
                            <v-card-title>{{ $t("technologyUsed") }}</v-card-title>
                            <v-card-text>
                                <v-chip-group active-class="deep-purple accent-4 white--text" column>
                                    <v-chip>Java 8</v-chip>
                                    <v-chip>HTML</v-chip>
                                    <v-chip>CSS</v-chip>
                                    <v-chip>Vuejs</v-chip>
                                    <v-chip>Spring Boot</v-chip>
                                    <v-chip>SQL Server</v-chip>
                                    <v-chip>Android Java</v-chip>
                                    <v-chip>Database Design</v-chip>
                                </v-chip-group>
                            </v-card-text>
                            <v-card-actions>
                                <v-btn color="deep-purple lighten-2" text @click="gotoPage(4)">{{$t("readMore")}}</v-btn>
                                <v-spacer></v-spacer>
                                <v-chip class="ma-2" color="green" label outlined @click:close="chip4 = false"> {{ $t("projectStatus1") }} </v-chip>
                            </v-card-actions>
                        </v-card>
                    </v-col>
                </v-slide-item>
                <v-slide-item>
                    <v-col xs="6">
                        <v-card :loading="loading" class="mx-auto my-12" max-width="374">
                            <template slot="progress">
                                <v-progress-linear color="deep-purple" height="10" indeterminate></v-progress-linear>
                            </template>
                            <v-img max-width="350" height="250" src="../assets/images/project2.png"></v-img>
                            <v-card-title>{{ $t("projectTitle2") }}</v-card-title>
                            <v-card-text>
                                <h3 class="project-subtitle mb-4">{{ $t("projectSubTitle2") }}</h3>
                                <div>
                                    {{ $t("projectDetail2") }}
                                </div>
                            </v-card-text>
                            <v-divider class="mx-4"></v-divider>
                            <v-card-title>{{ $t("technologyUsed") }}</v-card-title>
                            <v-card-text>
                                <v-chip-group active-class="deep-purple accent-4 white--text" column>
                                    <v-chip>ASP.NET</v-chip>
                                    <v-chip>HTML</v-chip>
                                    <v-chip>CSS</v-chip>
                                    <v-chip>Swift</v-chip>
                                    <v-chip>Objective C</v-chip>
                                    <v-chip>Firebase Notification</v-chip>
                                    <v-chip>SQL Server</v-chip>
                                    <v-chip>Database Design</v-chip>
                                </v-chip-group>
                            </v-card-text>
                            <v-card-actions>
                                <v-btn color="deep-purple lighten-2" text @click="reserve">{{$t("readMore")}}</v-btn>
                                <v-spacer></v-spacer>
                                <v-chip class="ma-2" color="green" label outlined @click:close="chip4 = false"> {{ $t("projectStatus1") }} </v-chip>
                            </v-card-actions>
                        </v-card>
                    </v-col>
                </v-slide-item>
                <v-slide-item>
                    <v-col xs="6">
                        <v-card :loading="loading" class="mx-auto my-12" max-width="374">
                            <template slot="progress">
                                <v-progress-linear color="deep-purple" height="10" indeterminate></v-progress-linear>
                            </template>
                            <v-img max-width="350" height="250" src="../assets/images/project3.png"></v-img>
                            <v-card-title>{{ $t("projectTitle3") }}</v-card-title>
                            <v-card-text>
                                <h3 class="project-subtitle mb-4">{{ $t("projectSubTitle3") }}</h3>
                                <div>
                                    {{ $t("projectDetail3") }}
                                </div>
                            </v-card-text>
                            <v-divider class="mx-4"></v-divider>
                            <v-card-title>{{ $t("technologyUsed") }}</v-card-title>
                            <v-card-text>
                                <v-chip-group active-class="deep-purple accent-4 white--text" column>
                                    <v-chip>Python</v-chip>
                                    <v-chip>Scrapy</v-chip>
                                    <v-chip>Curl</v-chip>
                                    <v-chip>Elastic Search</v-chip>
                                    <v-chip>Kibana</v-chip>
                                    <v-chip>Cron Job</v-chip>
                                    <v-chip>Data Visualization</v-chip>
                                    <v-chip>Postman</v-chip>
                                </v-chip-group>
                            </v-card-text>
                            <v-card-actions>
                                <v-btn color="deep-purple lighten-2" text @click="reserve">{{$t("readMore")}}</v-btn>
                                <v-spacer></v-spacer>
                                <v-chip class="ma-2" color="green" label outlined @click:close="chip4 = false"> {{ $t("projectStatus1") }} </v-chip>
                            </v-card-actions>
                        </v-card>
                    </v-col> 
                </v-slide-item>
                <v-slide-item>
                    <v-col xs="6">
                        <v-card :loading="loading" class="mx-auto my-12" max-width="374">
                            <template slot="progress">
                                <v-progress-linear color="deep-purple" height="10" indeterminate></v-progress-linear>
                            </template>
                            <v-img max-width="350" height="250" src="../assets/images/project4.png"></v-img>
                            <v-card-title>{{ $t("projectTitle4") }}</v-card-title>
                            <v-card-text>
                                <h3 class="project-subtitle mb-4">{{ $t("projectSubTitle4") }}</h3>
                                <div>
                                    {{ $t("projectDetail4") }}
                                </div>
                            </v-card-text>
                            <v-divider class="mx-4"></v-divider>
                            <v-card-title>{{ $t("technologyUsed") }}</v-card-title>
                            <v-card-text>
                                <v-chip-group active-class="deep-purple accent-4 white--text" column>
                                    <v-chip>Java 8</v-chip>
                                    <v-chip>HTML</v-chip>
                                    <v-chip>CSS</v-chip>
                                    <v-chip>Vuejs</v-chip>
                                    <v-chip>Spring Boot C</v-chip>
                                    <v-chip>SQL Server</v-chip>
                                    <v-chip>Android Java</v-chip>
                                    <v-chip>Database Design</v-chip>
                                </v-chip-group>
                            </v-card-text>
                            <v-card-actions>
                                <v-btn color="deep-purple lighten-2" text @click="reserve">{{$t("readMore")}}</v-btn>
                                <v-spacer></v-spacer>
                                <v-chip class="ma-2" color="green" label outlined @click:close="chip4 = false"> {{ $t("projectStatus1") }} </v-chip>
                            </v-card-actions>
                        </v-card>
                    </v-col>
                </v-slide-item>
                <v-slide-item>
                    <v-col>
                        <v-card :loading="loading" class="mx-auto my-12" max-width="374">
                            <template slot="progress">
                                <v-progress-linear color="deep-purple" height="10" indeterminate></v-progress-linear>
                            </template>
                            <v-img max-width="350" height="250" src="../assets/images/project5.webp"></v-img>
                            <v-card-title>{{ $t("projectTitle5") }}</v-card-title>
                            <v-card-text>
                                <h3 class="project-subtitle mb-4">{{ $t("projectSubTitle5") }}</h3>
                                <div>
                                    {{ $t("projectDetail5") }}
                                </div>
                            </v-card-text>
                            <v-divider class="mx-4"></v-divider>
                            <v-card-title>{{ $t("technologyUsed") }}</v-card-title>
                            <v-card-text>
                                <v-chip-group active-class="deep-purple accent-4 white--text" column>
                                    <v-chip>iOS</v-chip>
                                    <v-chip>Swift</v-chip>
                                    <v-chip>Alamofire</v-chip>
                                    <v-chip>Firebase</v-chip>
                                    <v-chip>Google Maps API</v-chip>
                                    <v-chip>Mongo DB</v-chip>
                                    <v-chip>Selenium</v-chip>
                                    <v-chip>Test Flight</v-chip>
                                </v-chip-group>
                            </v-card-text>
                            <v-card-actions>
                                <v-btn color="deep-purple lighten-2" text @click="reserve">{{$t("readMore")}}</v-btn>
                                <v-spacer></v-spacer>
                                <v-chip class="ma-2" color="green" label outlined @click:close="chip4 = false"> {{ $t("projectStatus1") }} </v-chip>
                            </v-card-actions>
                        </v-card>
                    </v-col>
                </v-slide-item>
                <v-slide-item>
                    <v-col>
                        <v-card :loading="loading" class="mx-auto my-12" max-width="374">
                            <template slot="progress">
                                <v-progress-linear color="deep-purple" height="10" indeterminate></v-progress-linear>
                            </template>
                            <v-img max-width="350" height="250" src="../assets/images/project6.png"></v-img>
                            <v-card-title>{{ $t("projectTitle6") }}</v-card-title>
                            <v-card-text>
                                <h3 class="project-subtitle mb-4">{{ $t("projectSubTitle6") }}</h3>
                                <div>
                                    {{ $t("projectDetail6") }}
                                </div>
                            </v-card-text>
                            <v-divider class="mx-4"></v-divider>
                            <v-card-title>{{ $t("technologyUsed") }}</v-card-title>
                            <v-card-text>
                                <v-chip-group active-class="deep-purple accent-4 white--text" column>
                                    <v-chip>SQL Optimization</v-chip>
                                    <v-chip>ERD Draw</v-chip>
                                    <v-chip>Function convert</v-chip>
                                    <v-chip>Synonym</v-chip>
                                    <v-chip>View</v-chip>
                                    <v-chip>Procedure</v-chip>
                                    <v-chip>Trigger</v-chip>
                                </v-chip-group>
                            </v-card-text>
                            <v-card-actions>
                                <v-btn color="deep-purple lighten-2" text @click="reserve">{{$t("readMore")}}</v-btn>
                                <v-spacer></v-spacer>
                                <v-chip class="ma-2" color="green" label outlined @click:close="chip4 = false"> {{ $t("projectStatus1") }} </v-chip>
                            </v-card-actions>
                        </v-card>
                    </v-col>
                </v-slide-item>
            </v-slide-group>
        </v-sheet>
        <v-spacer v-if="!isMobile()"></v-spacer>
    </v-row>
    <v-divider></v-divider>
    <v-row class="happy-client-row" justify="center">
        <h1 v-bind:style="!isMobile() ? '' : 'text-align: center;'" class="happy-client mb-5">{{$t("happyClientReview")}}</h1>
    </v-row>
    <v-row class="text-center">
        <v-col class="mb-4">
            <a href="https://www.codementor.io/@nltkien94?refer=badge">
                <img src="https://www.codementor.io/m-badges/nltkien94/im-a-cm-b.svg" alt="Codementor badge" />
            </a>
        </v-col>
    </v-row>
    <v-row>
        <v-container fluid>
            <v-carousel height="570" hide-delimiter-background show-arrows-on-hover>
                <v-carousel-item v-for="(item, i) in itemsCarousel" :key="i" reverse-transition="fade-transition" transition="fade-transition">
                    <v-row justify="center">
                        <v-avatar size="100">
                            <img :src="item.reviewAvatar" alt="John" />
                        </v-avatar>
                        <img src="../assets/socials/linkedin.svg" @click="openLinkedinURL(item.reviewURL)" class="review-linkedin" alt />
                    </v-row>
                    <v-row justify="center">
                        <h2 class="review-name">{{ item.reviewName }}</h2>
                    </v-row>
                    <v-row justify="center"> 
                        <h2 v-bind:style="!isMobile() ? '' : 'text-align: center;'" class="review-address">{{ item.reviewAddress }}</h2>
                    </v-row>
                    <v-row justify="center">
                        <v-rating color="warning" hover length="5" readonly size="40" value="5"></v-rating>
                    </v-row>
                    <v-row justify="center">
                        <h3 v-bind:style="!isMobile() ? '' : 'margin-left: 1rem; margin-right: 1rem'"  class="review-content">{{ item.reviewContent }}</h3>
                    </v-row>
                </v-carousel-item>
            </v-carousel>
        </v-container>
    </v-row>
    <v-divider class="mt-5"></v-divider>
    <v-row class="experienced-row" justify="center">
        <h1 class="happy-client mb-5">{{ $t("technicalSkill") }}</h1>
    </v-row>
    <v-row>
        <v-col>
            <v-card v-bind:style="!isMobile() ? '' : 'height: 100% !important'" class="mx-auto text-center" height="180" max-width="344" outlined>
                <h3 class="mb-4 mt-5">{{  $t("skillTitle7") }}</h3>
                <v-chip class="ma-2" color="deep-purple accent-4" outlined>Java</v-chip>
                <v-chip class="ma-2" color="deep-purple accent-4" outlined>Python</v-chip>
                <v-chip class="ma-2" color="deep-purple accent-4" outlined>C++</v-chip>
                <v-chip class="ma-2" color="deep-purple accent-4" outlined>Golang</v-chip>
                <v-chip class="ma-2" color="deep-purple accent-4" outlined>NodeJs</v-chip>
                <v-chip class="ma-2" color="deep-purple accent-4" outlined>Php Laravel</v-chip>
                <v-chip class="ma-2" color="deep-purple accent-4" outlined>C#</v-chip>
                <v-chip class="ma-2" color="deep-purple accent-4" outlined>VBA</v-chip>
            </v-card>
        </v-col>
        <v-col>
            <v-card v-bind:style="!isMobile() ? '' : 'height: 100% !important'" class="mx-auto text-center" height="180" max-width="344" outlined>
                <h3 class="mb-4 mt-5">{{  $t("skillTitle6") }}</h3>
                <v-chip class="ma-2" color="deep-purple accent-4" outlined>HTML</v-chip>
                <v-chip class="ma-2" color="deep-purple accent-4" outlined>CSS</v-chip>
                <v-chip class="ma-2" color="deep-purple accent-4" outlined>Javascript</v-chip>
                <v-chip class="ma-2" color="deep-purple accent-4" outlined>VueJs</v-chip>
                <v-chip class="ma-2" color="deep-purple accent-4" outlined>Reactjs</v-chip>
                <v-chip class="ma-2" color="deep-purple accent-4" outlined>JQuery</v-chip>
            </v-card>
        </v-col>
        <v-col>
            <v-card v-bind:style="!isMobile() ? '' : 'height: 100% !important'" class="mx-auto text-center" height="180" max-width="344" outlined>
                <h3 class="mb-4 mt-5">{{  $t("skillTitle5") }}</h3>
                <v-chip class="ma-2" color="deep-purple accent-4" outlined>Swift</v-chip>
                <v-chip class="ma-2" color="deep-purple accent-4" outlined>Objective C</v-chip>
                <v-chip class="ma-2" color="deep-purple accent-4" outlined>Kotlin</v-chip>
                <v-chip class="ma-2" color="deep-purple accent-4" outlined>Appium</v-chip>
                <v-chip class="ma-2" color="deep-purple accent-4" outlined>Zeplin</v-chip>
            </v-card>
        </v-col>
    </v-row> 
    <v-row>
        <v-col>
            <v-card v-bind:style="!isMobile() ? '' : 'height: 100% !important'" class="mx-auto text-center" height="230" max-width="344" outlined>
                <h3 class="mb-4 mt-5">{{  $t("skillTitle4") }}</h3>
                <v-chip class="ma-2" color="deep-purple accent-4" outlined>Postgresql</v-chip>
                <v-chip class="ma-2" color="deep-purple accent-4" outlined>MySql</v-chip>
                <v-chip class="ma-2" color="deep-purple accent-4" outlined>MongoDB</v-chip>
                <v-chip class="ma-2" color="deep-purple accent-4" outlined>ElasticSearch</v-chip>
                <v-chip class="ma-2" color="deep-purple accent-4" outlined>Oracle</v-chip>
                <v-chip class="ma-2" color="deep-purple accent-4" outlined>SqlServer</v-chip>
                <v-chip class="ma-2" color="deep-purple accent-4" outlined>Sqlite</v-chip>
            </v-card>
        </v-col>
        <v-col>
            <v-card v-bind:style="!isMobile() ? '' : 'height: 100% !important'" class="mx-auto text-center" height="230" max-width="344" outlined>
                <h3 class="mb-4 mt-5">{{  $t("skillTitle3") }}</h3>
                <v-chip class="ma-2" color="deep-purple accent-4" outlined>Spring</v-chip>
                <v-chip class="ma-2" color="deep-purple accent-4" outlined>Spring Boot</v-chip>
                <v-chip class="ma-2" color="deep-purple accent-4" outlined>Struts</v-chip>
                <v-chip class="ma-2" color="deep-purple accent-4" outlined>Hibernate</v-chip>
                <v-chip class="ma-2" color="deep-purple accent-4" outlined>MyBatis</v-chip>
                <v-chip class="ma-2" color="deep-purple accent-4" outlined>JPA</v-chip>
                <v-chip class="ma-2" color="deep-purple accent-4" outlined>Robot</v-chip>
            </v-card>
        </v-col>
        <v-col>
            <v-card v-bind:style="!isMobile() ? '' : 'height: 100% !important'" class="mx-auto text-center" height="230" max-width="344" outlined>
                <h3 class="mb-4 mt-5">{{ $t("skillTitle1") }}</h3>
                <v-chip class="ma-2" color="deep-purple accent-4" outlined>Automation</v-chip>
                <v-chip class="ma-2" color="deep-purple accent-4" outlined>Selenium</v-chip>
                <v-chip class="ma-2" color="deep-purple accent-4" outlined>Robot Framework</v-chip>
                <v-chip class="ma-2" color="deep-purple accent-4" outlined>Mockito</v-chip>
                <v-chip class="ma-2" color="deep-purple accent-4" outlined>Apache Jmeter</v-chip>
                <v-chip class="ma-2" color="deep-purple accent-4" outlined>API Testing</v-chip>
            </v-card>
        </v-col>
        <v-col>
            <v-card v-bind:style="!isMobile() ? '' : 'height: 100% !important'" class="mx-auto text-center" height="230" max-width="344" outlined>
                <h3 class="mb-4 mt-5">{{  $t("skillTitle2") }}</h3>
                <v-chip class="ma-2" color="deep-purple accent-4" outlined>Waterfall</v-chip>
                <v-chip class="ma-2" color="deep-purple accent-4" outlined>Scrum</v-chip>
                <v-chip class="ma-2" color="deep-purple accent-4" outlined>Excel Macro</v-chip>
                <v-chip class="ma-2" color="deep-purple accent-4" outlined>Power Point</v-chip>
                <v-chip class="ma-2" color="deep-purple accent-4" outlined>Sketch Design</v-chip>
            </v-card>
        </v-col>
    </v-row> 
    <v-row class="experienced-row" justify="center">
        <h1 class="happy-client mb-5">{{ $t("nonTechnicalSkill") }}</h1>
    </v-row>
    <v-row>
        <v-col>
            <v-card v-bind:style="!isMobile() ? '' : 'height: 100% !important'" class="mx-auto text-center" height="230" max-width="344" outlined>
                <h3 class="mb-4 mt-5">{{  $t("skillTitle8") }}</h3>
                <v-chip class="ma-2" color="deep-purple accent-4" outlined>Detail Design</v-chip>
                <v-chip class="ma-2" color="deep-purple accent-4" outlined>User Guidle</v-chip>
                <v-chip class="ma-2" color="deep-purple accent-4" outlined>Technical Article</v-chip>
            </v-card>
        </v-col>
        <v-col>
            <v-card v-bind:style="!isMobile() ? '' : 'height: 100% !important'" class="mx-auto text-center" height="230" max-width="344" outlined>
                <h3 class="mb-4 mt-5">{{  $t("skillTitle9") }}</h3>
                <v-chip class="ma-2" color="deep-purple accent-4" outlined>Problem Explain</v-chip>
                <v-chip class="ma-2" color="deep-purple accent-4" outlined>New Technology</v-chip>
                <v-chip class="ma-2" color="deep-purple accent-4" outlined>Bug Explain</v-chip>
            </v-card>
        </v-col>
        <v-col>
            <v-card v-bind:style="!isMobile() ? '' : 'height: 100% !important'" class="mx-auto text-center" height="230" max-width="344" outlined>
                <h3 class="mb-4 mt-5">{{  $t("skillTitle10") }}</h3>
                <v-chip class="ma-2" color="deep-purple accent-4" outlined>Communication</v-chip>
                <v-chip class="ma-2" color="deep-purple accent-4" outlined>Honesty</v-chip>
                <v-chip class="ma-2" color="deep-purple accent-4" outlined>Collaboration</v-chip>
            </v-card>
        </v-col>
        <v-col>
            <v-card v-bind:style="!isMobile() ? '' : 'height: 100% !important'" class="mx-auto text-center" height="230" max-width="344" outlined>
                <h3 class="mb-4 mt-5">{{  $t("skillTitle11") }}</h3>
                <v-chip class="ma-2" color="deep-purple accent-4" outlined>Strategic Thinking</v-chip>
                <v-chip class="ma-2" color="deep-purple accent-4" outlined>Planning</v-chip>
                <v-chip class="ma-2" color="deep-purple accent-4" outlined>Delivery</v-chip>
            </v-card>
        </v-col>
    </v-row>
    <v-row class="mt-5 mb=5" justify="center">
        <v-btn rounded class="btn-mentor-ship mr-5" depressed elevation="2" dark raised x-large @click="downloadCV()">{{ $t("downloadCV") }}</v-btn>
    </v-row>
    <v-divider class="mt-5"></v-divider>
    <v-row class="experienced-row" justify="center">
        <h1 class="happy-client mb-5">{{$t("experienced")}}</h1>
    </v-row>
    <v-row justify="center">
        <v-timeline>
            <v-timeline-item v-bind:style="!isMobile() ? '' : 'padding-left: 30px;padding-right: 30px;'" v-for="(year, i) in years" :key="i" :color="year.color" small>
                <template v-slot:opposite>
                    <span style="font-family: Circular-Std !important" :class="`headline font-weight-bold ${year.color}--text`" v-text="year.year"></span>
                </template>
                <div class="py-4">
                    <h2 style="font-family: Circular-Std !important" :class="`headline font-weight-light mb-4 ${year.color}--text`">{{ year.title }}</h2>
                    <h4 style="font-family: Circular-Std !important" :class="`headline font-weight-light mb-2 `">{{ year.companyName }}</h4>
                    <div>{{year.content}}</div>
                </div>
            </v-timeline-item>
        </v-timeline>
    </v-row>
    <v-divider class="mt-5"></v-divider>
    <v-row class="experienced-row" justify="center">
        <h1 class="happy-client mb-5">{{ $t("certification") }}</h1>
    </v-row>
    <v-row>
        <v-col>
            <v-card class="mx-auto" max-width="364">
                <v-row>
                    <v-col>
                        <div class="text-center">
                            <h4 class="text-center mb-4">{{$t("certificationName1")}}</h4>
                            <h3 class="mb-1">{{$t("certificationTitle1")}}</h3>
                            <div>
                                {{$t("certificationContent1")}}
                            </div>
                        </div>
                    </v-col>
                </v-row>
                <v-row>
                    <v-spacer></v-spacer>
                    <v-col>
                        <v-img width="200" height="250" src="../assets/images/abet-certification.jpg"></v-img>
                    </v-col>
                    <v-spacer></v-spacer>
                </v-row>
                <v-row>
                    <v-spacer></v-spacer>
                    <v-btn rounded class="btn-go-to-store text-center mb-2" depressed elevation="2" outlined dark raised x-medium text>{{ $t("detail") }}</v-btn>
                    <v-spacer></v-spacer>
                </v-row>
            </v-card>
        </v-col>
        <v-col>
            <v-card class="mx-auto" max-width="364">
                <v-row>
                    <v-col>
                        <div class="text-center">
                            <h4 class="text-center mb-4">{{$t("certificationName2")}}</h4>
                            <h3 class="mb-1">{{$t("certificationTitle2")}}</h3>
                            <div>
                                {{$t("certificationContent2")}}
                            </div>
                        </div>
                    </v-col>
                </v-row>
                <v-row>
                    <v-spacer></v-spacer>
                    <v-col>
                        <v-img width="200" height="250" src="../assets/images/abet-certification.jpg"></v-img>
                    </v-col>
                    <v-spacer></v-spacer>
                </v-row>
                <v-row>
                    <v-spacer></v-spacer>
                    <v-btn rounded class="btn-go-to-store text-center mb-2" depressed elevation="2" outlined dark raised x-medium text>{{ $t("detail") }}</v-btn>
                    <v-spacer></v-spacer>
                </v-row>
            </v-card>
        </v-col>
        <v-col>
            <v-card class="mx-auto" max-width="364">
                <v-row>
                    <v-col>
                        <div class="text-center">
                            <h4 class="text-center mb-4">{{$t("certificationName3")}}</h4>
                            <h3 class="mb-1">{{$t("certificationTitle2")}}</h3>
                            <div>
                                {{$t("certificationContent3")}}
                            </div>
                        </div>
                    </v-col>
                </v-row>
                <v-row>
                    <v-spacer></v-spacer>
                    <v-col>
                        <v-img width="200" height="250" src="../assets/images/oracle-certification.png"></v-img>
                    </v-col>
                    <v-spacer></v-spacer>
                </v-row>
                <v-row>
                    <v-spacer></v-spacer>
                    <v-btn rounded class="btn-go-to-store text-center mb-2" depressed elevation="2" outlined dark raised x-medium text>{{ $t("detail") }}</v-btn>
                    <v-spacer></v-spacer>
                </v-row>
            </v-card>
        </v-col>
    </v-row>
    <v-divider class="mt-5"></v-divider>
    <v-row class="experienced-row" justify="center">
        <h1 class="happy-client mb-5">{{$t("topBlogPost")}}</h1>
    </v-row>
    <v-row class="text-center">
        <v-col class="mb-6">
            <h2 class="subheading font-weight-regular">
                {{ $t("blogqoutes2") }}
                <a href="https://kieblog.vn" target="_blank">{{ $t("blogqoutes3") }}</a>{{ $t("blogqoutes4") }}
            </h2>
        </v-col>
    </v-row>
    <v-row class="mb-6 justify-center">
        <v-spacer v-if="!isMobile()"></v-spacer>
        <v-col>
            <v-card class="pa-2" v-bind:style="!isMobile() ? 'width:344px' : 'margin-left: 15px; margin-right: 15px'">
                <v-img src="https://mllo3znnpf9h.i.optimole.com/BQmFdg-8lDjkEep/w:768/h:500/q:auto/https://kieblog.vn/wp-content/uploads/2018/12/stream-la-gi.jpg" height="200px"></v-img>
                <v-card-title>{{$t("blogTitle1")}}</v-card-title>
                <v-card-subtitle>
                    {{$t("blogContent1")}}
                </v-card-subtitle>
                <v-card-actions>
                    <v-btn class="btn-read-now" text @click="goToBlog(1)">{{ $t("readMore") }}</v-btn>
                </v-card-actions>
            </v-card>
        </v-col>
        <v-col>
            <v-card class="pa-2" v-bind:style="!isMobile() ? 'width:344px' : 'margin-left: 15px; margin-right: 15px'">
                <v-img src="https://mllo3znnpf9h.i.optimole.com/BQmFdg-JPHdKQi2/w:776/h:508/q:auto/https://kieblog.vn/wp-content/uploads/2019/06/strategy-pattern-duck.jpg" height="200px"></v-img>
                <v-card-title>{{$t("blogTitle2")}}</v-card-title>
                <v-card-subtitle>
                    {{$t("blogContent2")}}
                </v-card-subtitle>
                <v-card-actions>
                    <v-btn class="btn-read-now" text @click="goToBlog(2)">{{ $t("readMore") }}</v-btn>
                </v-card-actions>
            </v-card>
        </v-col>
        <v-col>
            <v-card class="pa-2" v-bind:style="!isMobile() ? 'width:344px' : 'margin-left: 15px; margin-right: 15px'">
                <v-img src="https://mllo3znnpf9h.i.optimole.com/BQmFdg-CV_e6rSx/w:768/h:500/q:auto/https://kieblog.vn/wp-content/uploads/2020/08/graphql-the-best-choice.jpg" height="200px"></v-img>
                <v-card-title>{{$t("blogTitle3")}}</v-card-title>
                <v-card-subtitle>
                    {{$t("blogContent3")}}
                </v-card-subtitle>
                <v-card-actions>
                    <v-btn class="btn-read-now" text @click="goToBlog(3)">{{ $t("readMore") }}</v-btn>
                </v-card-actions>
            </v-card>
        </v-col>
        <v-spacer v-if="!isMobile()"></v-spacer>
    </v-row>
    <v-row class="mt-5 mb-5" justify="center">
        <v-btn rounded class="btn-mentor-ship mr-5" depressed elevation="2" dark raised x-large @click="goToMyBlog()">{{ $t("goToMyBlog") }}</v-btn>
    </v-row>
    <v-divider class="mt-5"></v-divider>
    <v-row class="experienced-row" justify="center">
        <h1 class="happy-client mb-5">{{ $t("recommendedBook") }}</h1>
    </v-row>
    <v-row>
        <v-col>
            <v-card class="mx-auto" max-width="500" outlined>
                <v-row>
                    <v-spacer v-if="!isMobile()"></v-spacer>
                    <v-col>
                        <h4 v-bind:style="!isMobile() ? '' : 'text-align: center !important'" class="book-title ml-4 mt-4">{{ $t("bookTitle1")}}</h4>
                        <h3 v-bind:style="!isMobile() ? '' : 'text-align: center !important'" class="ml-4 mt-4 text-no-wrap">Effective Java - Third Edition</h3>
                        <p v-bind:style="!isMobile() ? '' : 'text-align: center !important'" class="ml-4 mt-4 text-no-wrap">{{ $t("author") }}: Joshua Bloch</p>
                        <v-row v-if="isMobile()" justify="center" align="center">
                            <v-btn @click="goToBook(1)" rounded class="btn-go-to-store text-center ml-4 mt-4" depressed elevation="2" outlined dark raised x-medium text>{{ $t("buy") }}</v-btn>
                        </v-row>
                        <v-btn @click="goToBook(1)" v-if="!isMobile()" rounded class="btn-go-to-store text-center ml-4 mt-4" depressed elevation="2" outlined dark raised x-medium text>{{ $t("buy") }}</v-btn>
                    </v-col>
                    <v-col v-if="!isMobile()">
                        <img src="../assets/images/effective-java.jpg" height="190" width="140" color="grey" />
                    </v-col>
                    <v-spacer v-if="!isMobile()"></v-spacer>
                </v-row>
            </v-card>
        </v-col>
        <v-col>
            <v-card class="mx-auto" max-width="500" outlined>
                <v-row>
                    <v-spacer v-if="!isMobile()"></v-spacer>
                    <v-col>
                        <h4 v-bind:style="!isMobile() ? '' : 'text-align: center !important'" class="book-title ml-4 mt-4">{{ $t("bookTitle2")}}</h4>
                        <h3 v-bind:style="!isMobile() ? '' : 'text-align: center !important'"  class="ml-4 mt-4 text-no-wrap">Dive into Design Pattern</h3>
                        <p v-bind:style="!isMobile() ? '' : 'text-align: center !important'"  class="ml-4 mt-4 text-no-wrap">{{ $t("author") }}: Alexander Shvets</p>
                        <v-row v-if="isMobile()" justify="center" align="center">
                            <v-btn @click="goToBook(2)" rounded class="btn-go-to-store text-center ml-4 mt-4" depressed elevation="2" outlined dark raised x-medium text>{{ $t("buy") }}</v-btn>
                        </v-row>
                        <v-btn @click="goToBook(2)"  v-if="!isMobile()" rounded class="btn-go-to-store text-center ml-4 mt-4" depressed elevation="2" outlined dark raised x-medium text>{{ $t("buy") }}</v-btn>
                    </v-col>
                    <v-col v-if="!isMobile()">
                        <img src="../assets/images/dive-into-design-pattern.png" height="190" width="140" color="grey" />
                    </v-col>
                    <v-spacer v-if="!isMobile()"></v-spacer>
                </v-row>
            </v-card>
        </v-col>
    </v-row>
    <v-row>
        <v-col>
            <v-card class="mx-auto" max-width="500" outlined>
                <v-row>
                    <v-spacer v-if="!isMobile()"></v-spacer>
                    <v-col>
                        <h4 v-bind:style="!isMobile() ? '' : 'text-align: center !important'" class="book-title ml-4 mt-4">{{ $t("bookTitle3")}}</h4>
                        <h3 v-bind:style="!isMobile() ? '' : 'text-align: center !important'" class="ml-4 mt-4 text-no-wrap">Designing Distributed System</h3>
                        <p v-bind:style="!isMobile() ? '' : 'text-align: center !important'" class="ml-4 mt-4 text-no-wrap">{{ $t("author") }}: Brendan Burns</p>
                        <v-row v-if="isMobile()" justify="center" align="center">
                            <v-btn @click="goToBook(3)"  rounded class="btn-go-to-store text-center ml-4 mt-4" depressed elevation="2" outlined dark raised x-medium text>{{ $t("buy") }}</v-btn>
                        </v-row>
                        <v-btn @click="goToBook(3)" v-if="!isMobile()" rounded class="btn-go-to-store text-center ml-4 mt-4" depressed elevation="2" outlined dark raised x-medium text>{{ $t("buy") }}</v-btn>
                    </v-col>
                    <v-col v-if="!isMobile()" >
                        <img src="../assets/images/designing-distributed-systems.jpg" height="190" width="140" color="grey" />
                    </v-col>
                    <v-spacer v-if="!isMobile()"></v-spacer>
                </v-row>
            </v-card>
        </v-col>
        <v-col>
            <v-card class="mx-auto" max-width="500" outlined>
                <v-row>
                    <v-spacer v-if="!isMobile()"></v-spacer>
                    <v-col>
                        <h4 v-bind:style="!isMobile() ? '' : 'text-align: center !important'" class="book-title ml-4 mt-4">{{ $t("bookTitle4")}}</h4>
                        <h3 v-bind:style="!isMobile() ? '' : 'text-align: center !important'" class="ml-4 mt-4 text-no-wrap">Clean code a Handbook</h3>
                        <p v-bind:style="!isMobile() ? '' : 'text-align: center !important'" class="ml-4 mt-4 text-no-wrap">{{ $t("author") }}: Robert Martin</p>
                        <v-row v-if="isMobile()" justify="center" align="center">
                            <v-btn @click="goToBook(4)" rounded class="btn-go-to-store text-center ml-4 mt-4" depressed elevation="2" outlined dark raised x-medium text>{{ $t("buy") }}</v-btn>
                        </v-row>
                        <v-btn @click="goToBook(4)" v-if="!isMobile()" rounded class="btn-go-to-store text-center ml-4 mt-4" depressed elevation="2" outlined dark raised x-medium text>{{ $t("buy") }}</v-btn>
                    </v-col>
                    <v-col v-if="!isMobile()">
                        <img src="../assets/images/clean-code-uncle-bob.jpg" height="190" width="140" color="grey" />
                    </v-col>
                    <v-spacer v-if="!isMobile()"></v-spacer>
                </v-row>
            </v-card>
        </v-col>
    </v-row>
    <v-divider class="mt-5"></v-divider>
    <v-row class="happy-client-row" justify="center">
        <h1 class="happy-client mb-5">{{$t("image")}}</h1>
    </v-row>
    <v-row>
        <v-col>
        </v-col>
        <v-col>
            <v-carousel>
                <v-carousel-item
                v-for="(item,i) in itemsCarousel2"
                :key="i"
                :src="item.src"
                contain   
                reverse-transition="fade-transition"
                ></v-carousel-item>
            </v-carousel>
        </v-col>
        <v-col>
        </v-col>
    </v-row>
    <v-divider class="mt-5"></v-divider>
    <v-row class="experienced-row" justify="center">
        <h1 class="happy-client mb-5">{{ $t("contactMeLabel") }}</h1>
    </v-row>
    <v-row class="text-center mb-5">
        <v-col class="mb-4">
            <h2 class="subheading font-weight-regular">{{$t("contactlabel1")}}</h2>
            <h2>{{$t("contactlabel2")}}</h2>
        </v-col>
    </v-row>
    <div class="mb-4">
        <v-stepper v-model="e1">
            <v-stepper-header>
                <template v-for="n in steps">
                    <v-stepper-step :key="`${n}-step`" :complete="e1 > n" :step="n" color="#751fed" editable>{{ $t("step") }} {{ n }}</v-stepper-step>
                    <v-divider v-if="n !== steps" :key="n"></v-divider>
                </template>
            </v-stepper-header>
            <v-stepper-items >
                <v-stepper-content v-for="n in steps" :key="`${n}-content`" :step="n">  
            <validation-observer
              ref="observer"
              v-slot="{ invalid }">
              <form @submit.prevent="submit">
                <div v-if="n == 1" class="stepper-content">
                    <v-row>
                      <v-col cols="12" sm="8" md="6">
                        <h4>{{ $t("contactform1") }}</h4>
                      </v-col>
                    </v-row>
                    <v-row>
                    <v-col cols="12" sm="6" md="4">
                        <validation-provider
                            v-slot="{ errors }"
                            name="Name"
                            rules="required|min:5"
                        >
                            <v-text-field v-model="name"
                            color="#751fed"
                            :error-messages="errors"
                            v-bind:label="$t('contactNameLbl')"
                            tabindex="1"
                            required outlined dense></v-text-field>
                        </validation-provider>
                        <validation-provider
                            v-slot="{ errors }"
                            name="Facebook URL"
                            :rules="{ regex: /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/ }"
                        >
                            <v-text-field
                            color="#751fed"
                            v-model="facebook"
                            :error-messages="errors"
                            v-bind:label="$t('contactFacebookLbl')"
                            tabindex="3"
                            outlined
                            dense
                            ></v-text-field>
                        </validation-provider>
                        <v-btn tabindex="4" :disabled="invalid" class="btn-next" @click="nextStep(n)">{{ $t("next") }}</v-btn>
                    </v-col>
                    <v-col  cols="12" sm="6" md="4">
                        <validation-provider
                            v-slot="{ errors }"
                            name="Email"
                            rules="required|email"
                        >
                            <v-text-field v-model="email" 
                                :error-messages="errors" 
                                tabindex="2" 
                                v-bind:label="$t('contactEmail')"
                                outlined dense></v-text-field>
                        </validation-provider>
                    </v-col>
                    </v-row>
                </div>
                    <div v-if="n == 2">
                    <v-row>
                        <v-col cols="12" sm="10" md="8">
                        <h4>{{ $t("contactform2") }}</h4>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" sm="12" md="12">
                        <validation-provider
                            v-slot="{ errors }"
                            name="Message"
                            rules="required|min:5|max:3000"
                        >
                        <v-textarea
                            style="width:100%;"
                            color="#751fed"
                            outlined
                            tabindex="1" 
                            :error-messages="errors" 
                            v-model="messageContent" 
                            name="input-7-4"
                            v-bind:label="$t('contactContent')"
                            ></v-textarea>
                        </validation-provider>
                        <v-btn :disabled="invalid" class="btn-next" @click="nextStep(n)">{{ $t("next") }}</v-btn>
                        <v-btn text @click="previousStep(n)">{{ $t("back") }}</v-btn>
                        </v-col>
                    </v-row>
                    </div>
                </form>
            </validation-observer>
                    <div v-if="n == 3">
                        <v-row v-show="isLoading">
                            <v-col align="center" justify="center">
                                <v-progress-circular
                                    indeterminate
                                    color="purple"
                                ></v-progress-circular>
                            </v-col>
                        </v-row>
                        <div v-show="!isLoading">
                            <v-row v-show="!isContactSend">
                                <v-col cols="12" sm="10" md="8">
                                    <h4>{{ $t("contactform3") }}</h4>
                                </v-col>
                            </v-row>
                            <v-row v-show="isContactSend">
                                <v-col align="center" justify="center">
                                    <h3>{{ $t("contactform4") }}</h3>
                                </v-col>
                            </v-row>
                            <v-row v-show="!isContactSend" height="400">
                                <v-col cols="12" sm="10" md="8">
                                    <vue-recaptcha @verify="onVerify" sitekey="6LfRl-8ZAAAAACq84o8nIb4gM2CT247XewaTXK2_"></vue-recaptcha>
                                </v-col>
                            </v-row>
                            <v-btn v-show="!isContactSend" class="btn-next" :disabled="enableButtonSubmit" @click="submitContactInformation()">{{ $t("send") }}</v-btn>
                            <v-btn v-show="!isContactSend" text @click="previousStep(n)">{{ $t("back") }}</v-btn>
                        </div>
                    </div>
                </v-stepper-content>
            </v-stepper-items>
        </v-stepper>
    </div>
</v-container>
</template>

<script>
import VueRecaptcha from "vue-recaptcha";
import IconAnimation from "./IconAnimation";
import firebase from "../firebase.js";
import typical from '../typical.js'
import { required, digits, email, min, max, regex } from "vee-validate/dist/rules";
import {
  extend,
  ValidationObserver,
  ValidationProvider,
  setInteractionMode
} from "vee-validate";


setInteractionMode("aggressive");

extend("digits", {
  ...digits,
  message: "{_field_} needs to be {length} digits. ({_value_})"
});

extend("required", {
  ...required,
  message: "{_field_} can not be empty"
});

extend("min", {
  ...min,
  message: "{_field_} may should be be greater than {length} characters"
});

extend("max", {
  ...max,
  message: "{_field_} may not be greater than {length} characters"
});

extend("regex", {
  ...regex,
  message: "{_field_} {_value_} does not match {regex}"
});

extend("email", {
  ...email,
  message: "Email must be valid"
});

export default {
    components: {
        VueRecaptcha,
        IconAnimation, 
        ValidationProvider, 
        ValidationObserver,
        typical
    },
    data() {
        return {
            totalPageViews: 0,
            onLangChange: true,
            typicalStr1: null,
            typicalStr2: null,
            typicalStr3: null,
            loopNumber: null,
            name: '',
            email: '',
            facebook: '',
            messageContent: '',
            isContactSend: false,
            isLoading: false,
            itemsPerPageArray: [4, 8, 12],
            enableButtonSubmit: true,
            search: "",
            filter: {},
            dialog: false,
            sortDesc: false,
            page: 1,
            itemsPerPage: 4,
            sortBy: "name",
            e1: 1,
            steps: 3,
            loading: false,
            selection: 1,
            model: 0,
            participants: [{
                id: 'user1',
                name: 'Kien Nguyen',
                imageUrl: 'https://www.gravatar.com/avatar/ab1b68639635437dcffbafd1ab9c01fc?d=404&s=64'
            }], // the list of all the participant of the conversation. `name` is the user name, `id` is used to establish the author of a message, `imageUrl` is supposed to be the user avatar.
            titleImageUrl: 'https://a.slack-edge.com/66f9/img/avatars-teams/ava_0001-34.png',
            messageList: [{
                type: 'text',
                author: `user1`,
                data: {
                    text: this.$t("initialMessageChat")
                }
            }], // the list of the messages to show, can be paginated and adjusted dynamically
            newMessagesCount: 0,
            isChatOpen: false, // to determine whether the chat window should be open or closed
            showTypingIndicator: '', // when set to a value matching the participant.id it shows the typing indicator for the specific user
            colors: {
                header: {
                    bg: '#751fed',
                    text: '#ffffff'
                },
                launcher: {
                    bg: '#751fed'
                },
                messageList: {
                    bg: '#ffffff'
                },
                sentMessage: {
                    bg: '#751fed',
                    text: '#ffffff'
                },
                receivedMessage: {
                    bg: '#eaeaea',
                    text: '#222222'
                },
                userInput: {
                    bg: '#f4f7f9',
                    text: '#565867'
                }
            }, // specifies the color scheme for the component
            alwaysScrollToBottom: false, // when set to true always scrolls the chat to the bottom when new events are in (new message, user starts typing...)
            messageStyling: true,
            itemsCarousel2: [
                {
                    src: require('../assets/images/photo1.jpg'),
                },
                {
                    src: require('../assets/images/photo2.jpg'),
                },
                {
                    src: require('../assets/images/work-with-client2.jpg'),
                },
                {
                    src: require('../assets/images/work-with-client1.png'),
                },
                {
                    src: require('../assets/images/photo4.png'),
                },
                {
                    src: require('../assets/images/photo5.png'),
                },
                {
                    src: require('../assets/images/photo6.png'),
                },
                {
                    src: require('../assets/images/photo7.png'),
                }
            ],
            itemsCarousel: [{
                    reviewAvatar: require("../assets/images/review1.jpg"),
                    reviewName: "Carlton McFarlane",
                    reviewURL: "https://www.linkedin.com/in/carltonmcfarlane/",
                    reviewAddress: this.$t("reviewAddress1"),
                    reviewContent: this.$t("reviewClient1")
                },
                {
                    reviewAvatar: require("../assets/images/review2.jpg"),
                    reviewName: "Sergio Moreno",
                    reviewURL: "https://www.linkedin.com/in/sergio-ma/",
                    reviewAddress: this.$t("reviewAddress2"),
                    reviewContent: this.$t("reviewClient2")
                },
                {
                    reviewAvatar:  require("../assets/images/review3.jpg"),
                    reviewName: "Matthew Holt",
                    reviewURL: "https://www.linkedin.com/in/matthewholt/",
                    reviewAddress: this.$t("reviewAddress3"),
                    reviewContent: this.$t("reviewClient3")
                },
                {
                    reviewAvatar:  require("../assets/images/review4.jpg"),
                    reviewName: "Adeoluwa Adelugba",
                    reviewURL: "https://www.linkedin.com/in/adelugba-adeoluwa-58a96329/",
                    reviewAddress: this.$t("reviewAddress4"),
                    reviewContent: this.$t("reviewClient4")
                },
                {
                    reviewAvatar: "https://www.gravatar.com/avatar/88cdca2c2c6cdc4acd075f6a4fed58c2?d=404&s=200",
                    reviewName: "Nicholas Ong",
                    reviewURL: "",
                    reviewAddress: this.$t("reviewAddress5"),
                    reviewContent: this.$t("reviewClient5")
                },
                {
                    reviewAvatar: "https://process.filestackapi.com/cache=expiry:max/resize=width:72/cpY1tMa9TdKq1W8iwDR7",
                    reviewName: "Ramon",
                    reviewAddress: this.$t("reviewAddress6"),
                    reviewContent: this.$t("reviewClient6")
                },
                {
                    reviewAvatar: "https://process.filestackapi.com/cache=expiry:max/resize=width:72/s3bKySGTTca4BMIXZEo1",
                    reviewName: "Elliot",
                    reviewURL: "",
                    reviewAddress: this.$t("reviewAddress7"),
                    reviewContent: this.$t("reviewClient7")
                },
                {
                    reviewAvatar: "https://media-exp1.licdn.com/dms/image/C5603AQEIVBwpXfeV4A/profile-displayphoto-shrink_400_400/0/1533715763059?e=1620864000&v=beta&t=UTLPVT3ZDWX3iTnoLplLCOKCrL5UXxmdiBNj2pU_ZvY",
                    reviewName: "Farangis Si",
                    reviewURL: "",
                    reviewAddress: this.$t("reviewAddress8"),
                    reviewContent: this.$t("reviewClient8")
                }
            ],
            languages: [{
                    name: "English",
                    languageCode: "en",
                    path: require("../assets/flags/en.png")
                },
                {
                    name: "Deutsche",
                    languageCode: "de",
                    path: require("../assets/flags/de.png")
                },
                {
                    name: "日本語",
                    languageCode: "ja",
                    path: require("../assets/flags/ja.png")
                }
            ],
            years: [{
                    color: "pink",
                    year: this.$t("yearMonthDay1"),
                    title: this.$t("positionTitle1"),
                    content: this.$t("jobContent1")
                },
                {
                    color: "cyan",
                    year: this.$t("yearMonthDay2"),
                    title: this.$t("positionTitle2"),
                    companyName: "Aureole It Inc",
                    content: this.$t("jobContent2")
                },
                {
                    color: "green",
                    year: this.$t("yearMonthDay3"),
                    companyName: "IMT Solutions Viet Nam",
                    title: this.$t("positionTitle3"),
                    content: this.$t("jobContent3")
                },
                {
                    color: "amber",
                    year: this.$t("yearMonthDay4"),
                    companyName: "Persol Process Technology Viet Nam",
                    title: this.$t("positionTitle4"),
                    content: this.$t("jobContent4")
                },
                {
                    color: "purple",
                    year: this.$t("yearMonthDay5"),
                    companyName: "FPT Software",
                    title: this.$t("positionTitle5"),
                    content: this.$t("jobContent5")
                }
            ]
        };
    },
    computed: {
        numberOfPages() {
            return Math.ceil(this.items.length / this.itemsPerPage);
        },
        filteredKeys() {
            return this.keys.filter(key => key !== "Name");
        },
        selectedLanguageFlag() {
            const vm = this;
            switch (vm.$i18n.locale) {
                case "en":
                    return require("../assets/flags/en.png");
                case "de":
                    return require("../assets/flags/de.png");
                case "ja":
                    return require("../assets/flags/ja.png");
            }
            return "";
        }
    },
    watch: {
        steps(val) {
            if (this.e1 > val) {
                this.e1 = val;
            }
        }
    },
    created() {
        this.typicalStr1 = this.$t("introduction1");
        this.typicalStr2 = this.$t("introduction5");
        this.typicalStr3 = this.$t("introduction6");
        this.loopNumber = 1000;
    },
    methods: {
        isMobile() {
            if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
                return true
            }
            return false;
        },
        gotoPage(pageId) {
            if (pageId === 1) {
                window.open("https://bdsvinh.com.vn");
            }
            if (pageId === 2) {
                window.open("https://hawkerpedia.com.sg/en");
            }
            if (pageId === 3) {
                window.open("https://mipart.com");
            }
            if (pageId === 4) {
                window.open("https://www.persol-pt.co.jp/miteras/");
            }
        },
        goToBook(bookId) {
            if (bookId === 1) {
                window.open("https://www.amazon.com/Effective-Java-Joshua-Bloch/dp/0134685997");
            }
            if (bookId === 2) {
                window.open("https://refactoring.guru/design-patterns/book");
            }
            if (bookId === 3) {
                window.open("https://www.amazon.com/Designing-Distributed-Systems-Patterns-Paradigms/dp/1491983647");
            }
            if (bookId === 4) {
                window.open("https://www.amazon.com/Clean-Code-Handbook-Software-Craftsmanship/dp/0132350882");
            }
        },
        selectLanguage(code) {
            this.onLangChange = false;
            const vm = this;
            vm.$root.setLanguage(code);
            setTimeout(() => {
                this.onLangChange = true;
                this.typicalStr1 = this.$t("introduction1");
                this.typicalStr2 = this.$t("introduction5");
                this.typicalStr3 = this.$t("introduction6");
            }
            , 2000);
        },
        clickSayHello() {
            this.isChatOpen = true;
        },
        onVerify: function (response) {
            if (response) {
                this.enableButtonSubmit = false;
            }
        },
        sendMessage(text) {
            if (text.length > 0) {
                this.newMessagesCount = this.isChatOpen ? this.newMessagesCount : this.newMessagesCount + 1
                this.onMessageWasSent({
                    author: 'support',
                    type: 'text',
                    data: {
                        text
                    }
                })
            }
        },
        submitContactInformation() {
            var currentDate = new Date();
            var currentDateStr = currentDate.toString();
            var contactInfo = {
                "name": this.name,
                "email": this.email,
                "facebookURL": this.facebook,
                "message": this.messageContent,
                "date": currentDateStr
            }
            this.isLoading = true;
            firebase.ref("contacts").push(contactInfo)
                .then(() => {
                    this.isLoading = false;
                    this.isContactSend = true;
                })
                .catch((error) => {
                    this.isLoading = false;
                    console.log(error);
                });
        },
        onMessageWasSent(message) {
            var currentDate = new Date();
            var currentDateStr = currentDate.toString();
            var messageInfo = {
                "message": message,
                "date": currentDateStr
            }
            firebase.ref("messages").push(messageInfo)
                .then(() => {
                })
                .catch((error) => {
                    console.log(error);
                });
            // called when the user sends a message
            this.messageList = [...this.messageList, message]
        },
        openChat() {
            // called when the user clicks on the fab button to open the chat
            this.isChatOpen = true
            this.newMessagesCount = 0
        },
        closeChat() {
            // called when the user clicks on the botton to close the chat
            this.isChatOpen = false
        },
        handleScrollToTop() {
            // called when the user scrolls message list to top
            // leverage pagination for loading another page of messages
        },
        handleOnType() {},
        editMessage(message) {
            const m = this.messageList.find(m => m.id === message.id);
            m.isEdited = true;
            m.data.text = message.data.text;
        },
        goToBlog(siteId) {
            if (siteId === 1) {
                window.open("https://kieblog.vn/stream-trong-java8-la-gi/");
            }
            if (siteId === 2) {
                window.open("https://kieblog.vn/strategy-pattern-vi-du-trong-java/");
            }
            if (siteId === 3) {
                window.open("https://kieblog.vn/whats-graphql-where-we-can-start/");
            }
        },
        goToMyBlog() {
            window.open("https://kieblog.vn/stream-trong-java8-la-gi/");
        },
        downloadCV() {
            window.open(
                "https://drive.google.com/file/d/1338z-tE8nfiV2Ls6TvURZP60deKs2o57/view?usp=sharing"
            );
        },
        goToMyblog() {
            window.open(
                "https://drive.google.com/file/d/1338z-tE8nfiV2Ls6TvURZP60deKs2o57/view?usp=sharing"
            );
        },
        openLinkedinURL(linkedInURL) {
            window.open(linkedInURL);
        },
        nextPage() {
            if (this.page + 1 <= this.numberOfPages) this.page += 1;
        },
        formerPage() {
            if (this.page - 1 >= 1) this.page -= 1;
        },
        updateItemsPerPage(number) {
            this.itemsPerPage = number;
        },
        nextStep(n) {
            if (n === this.steps) {
                this.e1 = 1;
            } else {
                this.e1 = n + 1;
            }
        },
        previousStep(n) {
            this.e1 = n - 1;
        },
        reserve() {
            this.loading = true;

            setTimeout(() => (this.loading = false), 2000);
        }
    }
};
</script>

<style lang="scss" scoped>
@import "../style/style.scss";

a {
    color: $website-main-color !important;
}

h1,
h2,
h3,
h4,
li {
    color: $website-text-color !important;
}

.v-timeline-item__body .v-timeline-item__opposite .v-timeline-item__divider {
    font-family: Circular-Std !important;
}

.strongest-skill {
    background-color: $website-main-color !important;
}

.toolbar-menu-item {
    padding-left: 5px;
}

.project-subtitle {
    font-family: Circular-Std !important;
    font-weight: 300;
}

.selected-language-flag {
    max-width: 35px;
}

.language-flag {
    max-width: 40px;
    margin-right: 15px;
}

.languages-list-item {
    cursor: pointer;
    margin-top: -2px;
    margin-left: 1px;
}

.languages-list-item-title {
    font-size: 16px;
    margin-bottom: 5px;
    color: $website-text-color;
}

.languages-list-item-title:hover {
    color: #41b883;
    font-weight: bold;
}

.language-menu {
    border-radius: 25px;
    width: 235px;
    margin-right: 10px;
}

.btn-mentor-ship {
    background-color: $website-main-color !important;
}

.btn-next {
    background-color: $website-main-color !important;
    color: white !important;
}

.book-title {
    font-family: Circular-Std !important;
}

.btn-become-mentor {
    border: none;
    font-size: 16px !important;
    color: $website-text-color;
}

.job-title {
    font-family: "Circular-Std-Black" !important;
    margin-top: 3.2rem;
    color: $website-text-color;
    font-size: 60px !important;
    font-weight: bold;
}

.become-mentor {
  padding-top: 1em;
}

.welcome-content {
    margin-top: 2.5rem;
    color: $website-text-color;
    font-size: 45px !important;
    font-family: "Circular-Std-Black" !important;
}

.welcome-content-2 {
    color: $website-text-color;
    font-size: 37px !important;
    font-family: "Circular-Std-Black" !important;
}

.review-name {
    color: $website-text-color;
    font-family: Circular-Std !important;
    margin-top: 1rem;
}

.review-address {
    color: $website-text-color;
}

.review-content {
    color: $website-text-color;
    margin-top: 3rem;
    width: 600px;
    text-align: center;
}

.review-linkedin {
    width: 30px;
    height: 30px;
}

.happy-client {
    color: $website-text-color;
    font-family: "Circular-Std-Black" !important;
}

.happy-client-row {
    margin-top: 6rem;
    margin-bottom: 2rem;
}

.experienced-row {
    margin-top: 6rem;
    margin-bottom: 2rem;
}

.btn-read-now {
    color: $website-main-color !important;
}

.btn-go-to-store {
    color: $website-main-color !important;
    border-color: $website-main-color !important;
}

.v-btn--icon.v-size--small {
    height: 20px !important;
    width: 20px !important;
}

.v-card__title {
    word-break: break-word !important;
}

.stepper-number {
    background-color: $website-main-color !important;
}
</style>
